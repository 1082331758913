.menu {
	display: flex;
	flex-direction: column;
}

.page{
    margin: 5px;
}

.container {
  display: flex;
  flex-direction: row;
	justify-content: space-around;
	margin-top: 100px;
	width: 100%;
}

@media (max-width: 800px) {
  .container {
    flex-direction: column;
    margin-top: 80px;
    width: 99%;
  }
}

/*--------ENROLLMENTS-------*/
.mainDiv {
  margin: auto;
  margin-top: 125px;
  margin-bottom: 100px;
  max-width: 1500px;
  width: 80%;
  background-color: aliceblue;
}

.enrollDiv,
.absenceDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
}
.absenceDiv {
  align-items: flex-start;
}

.innerEnrollDiv,
.innerAbsenceDiv {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  width: 700px;
}

.innerAbsenceDiv {
  box-sizing: border-box;
  width: 50%;
  padding: 40px;
}

.innerAbsenceDiv > *:not(:last-child) {
  margin-bottom: 40px;
}

.iconDiv {
  padding-top: 20px;
	text-align: center;
  width: 100%;
  }

  .bigFlexList {
    overflow-y: auto;
    width: 80%;
    height: 470px;
  }

  .bigFlexList::-webkit-scrollbar {
			width: "0.4em";
	}

  .bigFlexList::-webkit-scrollbar-track {
			box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
	}

  .bigFlexList::-webkit-scrollbar-thumb {
			background-color: "rgba(0, 0, 0,.2)";
			outline: "1px solid slategrey";
	}
  

@media (max-width: 900px) {
  .enrollDiv {
    flex-direction: column;
    width: 100%;
    padding: 0px;
    margin-bottom: 50px;
  }

  .mainDiv {
    margin-top: 75px;
    margin-bottom: 0;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 3px;
    width: 100%;
    background-color: aliceblue;
  }
  
  .innerEnrollDiv {
    width: 100%;
    align-content: center;
  }

  .absenceDiv {
    flex-direction: column;
    padding: 40px 0;
  }
  .innerAbsenceDiv {
    width: 100%;
    padding: 0 40px;
  }

  .bigFlexList {
    height: 20vh,;
	  width: 99%;
		text-overflow: ellipsis;
		margin: 2;
  }
}
