.courseItem {
	margin: 20px;
	padding: 25px;
	background-color: aliceblue;
}

.additionalInfo {
	opacity: 0.6;
	word-wrap: break-word;
	white-space: pre-wrap;
	width: 50%;
};

.inputContainer {
	display: block;
	flex-direction: column;
	justify-content: center;
	align-content: space-around;
	flex-wrap: wrap;
	width: 100%;
}

.inputElement {
	width: 100%;
	margin-top: 25px;
	margin-bottom: 25px;
}

.wide {
	width: 85%;
}

.enrollPaper {
	position: relative;
	padding: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	box-shadow: 0px 24px 36px rgba(131,153,167,0.99);
	opacity: 0.85;
	margin: auto;
	margin-top: 5px;
}

.detailPaper {
	position: relative;
	padding: 20px;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 24px 36px rgba(131,153,167,0.99);
	opacity: 0.85;
	margin: 25px;
	align-items: center;
	justify-content: space-around;
}

.enrolledListPaper {
	position: relative;
	display: flex;
	padding-top: 20px;
	padding-bottom: 20px;
	flex-direction: column;
	box-shadow: 0px 24px 36px rgba(131,153,167,0.99);
	opacity: 0.85;
	margin-bottom: 25px;
	margin-left: 25px;
	margin-right: 25px;
	align-items: center;
}

.enrolledPersonList {
	width: 100%;
}

.paymentPaper {	
	margin-top: 40px;
	position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
	width: 95%;
	height: 82%;
}

.paymentRowFlex {
  display: flex;
  flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
}

.paymentColumnFlex {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	height: 55vh;
}

@media (max-width: 900px) {
	.enrollPaper {
		position: relative;
		padding: 10px;
		padding-top: 20px;
		padding-bottom: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		box-shadow: 0px 24px 36px rgba(131,153,167,0.99);
		opacity: 0.85;
		margin: auto;
	}
	
	.detailPaper {
		position: relative;
		padding: 10px;
		padding-top: 20px;
		padding-bottom: 20px;
		display: flex;
		flex-direction: column;
		box-shadow: 0px 24px 36px rgba(131,153,167,0.99);
		opacity: 0.85;
		margin: 10px;
	}

	.enrolledListPaper {
		position: relative;
		padding-top: 20px;
		padding-bottom: 20px;
		display: flex;
		flex-direction: column;
		box-shadow: 0px 24px 36px rgba(131,153,167,0.99);
		opacity: 0.85;
		margin: 10px;
		align-items: stretch;
	}

	.enrolledPersonList {
		width: 100%;
	}
}

.detailPaper:hover {
	box-shadow: 0px 24px 36px rgba(65, 70, 73, 0.99)
}

.enrolledListPaper:hover {
	box-shadow: 0px 24px 36px rgba(65, 70, 73, 0.99)
}

.enrollPaper:hover {
	box-shadow: 0px 24px 36px rgba(65, 70, 73, 0.99)
}

.paper:hover {
	box-shadow: 0px 24px 36px rgba(131,153,167,0.99)
}

.errorMessage {
	color: red;
	font-size: 13px;
	width: 250px;
	flex-wrap: wrap;
}

.title {
	text-align: center;
}

.progress {
	align-self: center;
}

.flexDetailsLeft {
	flex: 50%;
}

table {
	width: 100%;
}

td {
	height: 20px;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	  sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	  monospace;
  }
  
  .row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
  }
  
  .column {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  }

.primary {
    display: flex;
    align-items: center;
		flex-wrap: wrap;
		padding-bottom: 20px;
}

.icon {
	margin-right: 12px;
}

.notesIcon {
	margin-left: 20px;
}

.hideDays div[class*='Cell-dayOfMonth-']{
	display: none;
}
