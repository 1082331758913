.inputContainer {
	background-color: white;
	display: block;
	flex-direction: column;
	justify-content: center;
	align-content: space-around;
	flex-wrap: wrap;
	width: 90%;
}

.inputElement {
	width: 100%;
	margin-top: 25px;
	margin-bottom: 25px;
}

.wide {
	width: 100%;
}

.paper {
	background-color: white;
	position: relative;
	padding: 10px;
	padding-top: 20px;
	padding-bottom: 20px;
	width: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	box-shadow: 0px 24px 36px rgba(131,153,167,0.99);
	margin: auto;
}

.paper:hover {
	box-shadow: 0px 24px 36px rgba(131,153,167,0.99)
}

.errorMessage {
	width: 95%;
	color: red;
	font-size: 13px;
	padding-left: 10px;
	padding-right: 10px;
	flex-wrap: wrap;
}

.progress {
	align-self: center;
}

.flexDetailsLeft {
	flex: 50%;
}

.flexFormDiv {
	padding: 20px;
	flex: 50%;
}

table {
	width: 100%;
}

td {
	height: 20px;
}

.courseItem {
	margin: 20px;
	padding: 20px;
	background-color: aliceblue;
}
  
  code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	  monospace;
  }
  
  .row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
  }
  
  .column {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  }

  .iconDiv {
	text-align: center;
  }

  .formDiv {
	margin: auto;
	margin-top: 125px;
	margin-bottom: 75px;
}

  @media (max-width: 500px) {
	.paper {
		position: relative;
		padding: 0%;
		padding-top: 20px;
		padding-bottom: 20px;
		width: 95%;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		box-shadow: 0px 24px 36px rgba(131,153,167,0.99);
	}

	.inputContainer {
		display: block;
		flex-direction: column;
		justify-content: center;
		align-content: space-around;
		flex-wrap: wrap;
		width: 95%;
	}

	.formDiv {
		margin: auto;
		margin-top: 110px;
		margin-bottom: 30px;
	}

	.flexFormDiv {
		padding: 10px;
		flex: 50%;
	}
  }
